import React from "react";
import i18n from "../../utils/i18n";
import styled from "styled-components";

const SelectionContainer = styled.div``;

const LangSelection = styled.select`
  background-color: ${(props) => props.theme.selection.bg};
  border: 1px solid #fe59d7;
  color: ${(props) => props.theme.selection.color};
  padding: 5px 10px;
  border-radius: 4px;
  font-family: "Kanit", sans-serif;
`;

function LanguageSelect() {
  const changeLang = (e) => {
    const lng = e.target.value;
    i18n.changeLanguage(lng);
  };

  return (
    <SelectionContainer onChange={changeLang}>
      <LangSelection>
        <option value="en">English</option>
        <option value="pt">Português</option>
      </LangSelection>
    </SelectionContainer>
  );
}

export default LanguageSelect;
