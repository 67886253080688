export const width = {
  large: 1200,
  mediumLarge: 1000,
  medium: 800,
  smallMedium: 560,
  small: 400,
  extraSmall: 250,
};

export const height = {
  large: 950,
  mediumLarge: 800,
  medium: 750,
  smallMedium: 620,
  small: 580,
  extraSmall: 500,
};
