import React from "react";
import styled from "styled-components";
import { width } from "../../utils/media";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import ThemeSwitch from "../ThemeSwitch/ThemeSwitch";

const NavContainer = styled.div`
  padding: 10px 0;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;

  @media screen and (max-width: ${width.mediumLarge}px) {
    justify-content: space-between;
  }
`;

function Navigation({ theme, changeTheme }) {
  return (
    <NavContainer>
      <ThemeSwitch theme={theme} changeTheme={changeTheme} />
      <LanguageSelect />
    </NavContainer>
  );
}

export default Navigation;
