export const light = {
  name: "light",
  color: {
    title: "#3b064d",
    primary: "#3b064d",
    secondary: "#fe59d7",
    bg: "white",
    white: "white",
  },
  textInput: {
    bg: "white",
    color: "black",
    border: "#3b064d",
  },
  selection: {
    bg: "white",
    color: "#3b064d",
  },
  warning: {
    color: "black;",
  },
  button: {},
};

export const dark = {
  name: "dark",
  color: {
    title: "white",
    primary: "#3b064d",
    secondary: "#fe59d7",
    bg: "#0d0d0d",
    white: "white",
  },
  textInput: {
    bg: "#0d0d0d",
    color: "white",
    border: "#fe59d7",
  },
  selection: {
    bg: "#0d0d0d",
    color: "#fe59d7",
  },
  warning: {
    color: "white;",
  },
};
