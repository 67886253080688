import React from "react";
import styled from "styled-components";

const Input = styled.input`
  background-color: ${(props) => props.theme.textInput.bg};
  border: 1px solid ${(props) => props.theme.textInput.border};
  border-radius: 4px;
  height: 50px;

  &::placeholder {
    color: ${(props) => props.theme.textInput.color};
    opacity: 0.5;
  }
`;

function InlineInput({ text, onChange, placeholder }) {
  return <Input value={text} onChange={onChange} placeholder={placeholder} />;
}

export default InlineInput;
