import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { width } from "../../utils/media";
/**
 * Button base and style.
 */
const ButtonBase = styled.button`
  border: none;
  padding: 5px 15px;
  background-color: #fe59d7;
  color: white;
  font-family: "Noto Sans", sans-serif;
  font-size: 24px;
  border-radius: 4px;
  transition: background-color 0.2s ease-out;

  &:hover {
    cursor: pointer;
    background-color: #9f3987;
    transition: background-color 0.2s ease-in;
  }

  @media screen and (max-width: ${width.medium}px) {
    width: 100%;
    height: 50px;
    margin-top: 10px;
  }
`;

/**
 *
 * @param text Text inside the button.
 * @param onClick Function that will run when click on the button.
 * @returns The button component.
 */
function Button({
  text,
  onClick,
}: {
  text: string;
  onClick: React.MouseEvent<HTMLElement>;
}) {
  const { t } = useTranslation();
  return <ButtonBase onClick={onClick}>{t("confirm")}</ButtonBase>;
}

export default Button;
