import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        warning:
          "This website still in Alpha. PDF generation is entire done at front-end (client) and still not perfect. I'm working on that.",
        confirm: "Confirm",
        textPlaceholder: "Insert Japanese text here:",
      },
    },
    pt: {
      translation: {
        warning:
          "Este website ainda está em Alfa. A geração de PDF é feita inteiramente no browser e ainda não é perfeita. Estou trabalhando nisso.",
        confirm: "Confirmar",
        textPlaceholder: "Insira o texto em japonês aqui:",
      },
    },
  },
  fallbackLng: "en",
});

export default i18n;
