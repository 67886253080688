import React from "react";
import styled from "styled-components";
import { width } from "../../utils/media";

/**
 * Container for the whole thing.
 * So it can take the whole width;
 */
const Container = styled.div`
  display: flex;
  justify-content: center;
`;

/**
 * Container for the text and the badge
 */
const TextContainer = styled.div`
  position: relative;
  width: fit-content;
`;

/**
 * Header
 */
const Header = styled.h1`
  color: ${(props) => props.theme.color.title};
  text-align: center;
  font-weight: 700;
  font-family: "Kanit", sans-serif;
  font-size: 96px;
  margin: 0;

  @media screen and (max-width: ${width.smallMedium}px) {
    font-size: 74px;
  }

  @media screen and (max-width: ${width.small}px) {
    font-size: 48px;
  }
`;

/**
 * Badge to show the version or other info besides the logo
 */
const Badge = styled.div`
  position: absolute;
  bottom: 40px;
  right: -30px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.color ? props.color : "#000")};
  color: white;
  font-family: "Noto Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;

  @media screen and (max-width: ${width.smallMedium}px) {
    bottom: 30px;
    right: -30px;
    font-size: 10px;
  }

  @media screen and (max-width: ${width.small}px) {
    bottom: 18px;
    right: -38px;
  }

  @media screen and (max-width: ${width.extraSmall}px) {
    bottom: 5px;
    right: calc(50% - 25px);
  }
`;

/**
 * Title of the web page.
 * @returns the Title component including the name and the badge.
 */
function Title() {
  return (
    <Container>
      <TextContainer>
        <Header>Sakubun</Header>
        <Badge color="#fe59d7">PDF</Badge>
        {/* <Badge color='#7e80e3'>Beta</Badge> */}
      </TextContainer>
    </Container>
  );
}

export default Title;
