import { jsPDF } from "jspdf";
import { GenJyuu } from "./fonts/genJyuu";

/**
 *
 * @param text String containing the text that will be broken
 * @returns the text spliced and splited as necessary
 */
export const breakText = (text: string) => {
  const chunkSizeWidth = 20;
  const chunkSizeHeight = 20;
  // const chunkSize = chunkSizeWidth * chunkSizeWidth;
  const chunkSize = 20;
  const breakLineText = text.split("\n"); // first step, break the text when it breaks line.
  const breakInSize = breakLineText.map((line) => {
    return line.match(/.{1,20}/g);
  }); // breaking into groups of 25.
  const cleanText = breakInSize.flat().filter((el) => el !== null);

  const spliceText: Array<Array<string>> = [];
  for (let chunk = 0; chunk < cleanText.length; chunk = chunk + chunkSize) {
    const sliceText = cleanText.slice(chunk, chunk + chunkSize);
    spliceText.push(sliceText as string[]);
  }
  return spliceText;
};

/**
 * Generates Sakubun squares layout
 * @param doc jsPDF instance to generate graphics on the page
 */
const generatePageLayout = (doc: jsPDF) => {
  for (let i = 0; i < 20; i = i + 1) {
    for (let j = 0; j < 20; j = j + 1) {
      doc.rect(14.5 * i + 5, 10 * j + 5, 10, 10);
    }
  }
};

/**
 * Generates blank page only with the blank layout
 */
export const generateBlankPage = () => {
  const doc = new jsPDF({
    orientation: "landscape",
  });
  generatePageLayout(doc);
  doc.save("blank-sakubun.pdf");
};

const generateFileName = (text: string) => {
  if (text.length === 0) {
    return "sakubun.pdf";
  }
  if (text.includes(".pdf")) {
    if (text === ".pdf") {
      return "haha-very-funny.pdf";
    }
    return text;
  }
  return `${text}.pdf`;
};

/**
 * Generate PDF content the text
 * @param text Text input
 */
export const generatePdf = (text: string, fileName: string) => {
  const doc = new jsPDF({
    orientation: "landscape",
  });

  const textBroken = breakText(text);

  doc.addFileToVFS("Gen Jyuu Gothic Monospace Regular-normal.ttf", GenJyuu);
  doc.addFont(
    "Gen Jyuu Gothic Monospace Regular-normal.ttf",
    "Gen Jyuu Gothic Monospace Regular",
    "normal"
  );
  doc.setFont("Gen Jyuu Gothic Monospace Regular", "normal");
  doc.setFontSize(20);

  textBroken.map((page, index) => {
    if (index !== 0) doc.addPage();

    generatePageLayout(doc);

    // Write text
    for (let i = 19; i >= 0; i = i - 1) {
      const line = page[19 - i];
      if (line !== undefined) {
        const words = line.split("");
        for (let j = 0; j < words.length; j = j + 1) {
          if (words[j] === "ー" || words[j] === "一") {
            doc.text(words[j], 14.5 * i + 12.5, 10 * j + 13, {
              angle: 90,
            });
          } else {
            doc.text(words[j], 14.5 * i + 6.5, 10 * j + 13);
          }
        }
      }
    }
  });

  doc.save(generateFileName(fileName));
};
