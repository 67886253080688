import React, { useState } from "react";
import styled from "styled-components";
import { generateBlankPage, generatePdf } from "../../utils/generatePdf";
import Button from "../Button/Button";
import TextInput from "../TextInput/TextInput";
import { height, width } from "../../utils/media";
import InlineInput from "../InlineInput/InlineInput";

const Container = styled.div`
  width: 100%;

  @media screen and (max-height: ${height.medium}px) {
    margin-bottom: 50px;
  }
`;

const ButtonContainer = styled.div`
  width: inherit;
  margin-top: 10px;
  display: flex;
  justify-content: end;
  gap: 10px;
  @media screen and (max-width: ${width.medium}px) {
    justify-content: center;
  }

  @media screen and (max-width: ${width.small}px) {
    flex-direction: column;
  }
`;

/**
 * Generator is a component that contains the text and the button to call the generators.
 * It will also keep the text that will be used on the pdf.
 * @returns The Generator layout container
 */
function Generator() {
  const [text, setText] = useState("");
  const [fileName, setFileName] = useState("");

  const changeText = (e) => {
    setText(e.target.value);
  };

  const changeFileName = (e) => {
    setFileName(e.target.value);
  };

  const generate = () => {
    if (text === "" || text === undefined || text === null) {
      generateBlankPage();
    } else {
      generatePdf(text, fileName);
    }
  };

  return (
    <Container>
      <TextInput text={text} onChange={changeText} />
      <ButtonContainer>
        <InlineInput
          text={fileName}
          onChange={changeFileName}
          placeholder="sakubun.pdf"
        />
        <Button text="Confirm" onClick={generate} />
      </ButtonContainer>
    </Container>
  );
}

export default Generator;
