import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Input = styled.textarea`
  width: 100%;
  border: 1px solid ${(props) => props.theme.textInput.border};
  background-color: ${(props) => props.theme.textInput.bg};
  color: ${(props) => props.theme.textInput.color};
  font-size: 24px;
  resize: none;

  &::placeholder {
    color: ${(props) => props.theme.textInput.color};
    opacity: 0.5;
  }
`;

// Temporary part, just to let people know about the project.
const Warning = styled.div`
  font-family: sans-serif;
  text-size: 10px;
  margin: 10px 0;
  color: ${(props) => props.theme.warning.color};
`;

/**
 *
 * @param text string text that will appear on the textarea.
 * @param onChange onChange function that will run everytime the text area changes.
 * @returns the TextInput component with it's layout.
 */
function TextInput({
  text,
  onChange,
}: {
  text: string;
  onChange: React.FormEvent<HTMLInputElement>;
}) {
  const { t } = useTranslation();

  return (
    <>
      <Input
        value={text}
        onChange={onChange}
        rows="15"
        placeholder={t("textPlaceholder")}
      />
      <Warning>{t("warning")}</Warning>
    </>
  );
}

export default TextInput;
