import React, { useState } from "react";
import styled, { ThemeProvider } from "styled-components";
import Generator from "./components/Generator/Generator";
import Navigation from "./components/Navigation/Navigation";
import Title from "./components/Title/Title";
import { height, width } from "./utils/media";
import Footer from "./components/Footer/Footer";
import { light } from "./utils/themes";

const PageContainer = styled.div`
  margin: 0;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: start;
  // align-items: center;
  padding: 0 300px;
  background-color: ${(props) => props.theme.color.bg};
  height: 100vh;

  @media screen and (max-width: ${width.mediumLarge}px) {
    padding: 0 100px;
  }

  @media screen and (max-width: ${width.smallMedium}px) {
    padding: 0 50px;
  }

  @media screen and (max-width: ${width.small}px) {
    padding: 0 10px;
  }

  @media screen and (max-height: ${height.medium}px) {
    overflow-y: auto;
  }
`;

function App() {
  const [theme, setTheme] = useState(light);

  return (
    <ThemeProvider theme={theme}>
      <PageContainer>
        <Navigation theme={theme} changeTheme={setTheme} />
        <Title />
        <Generator />
        <Footer />
      </PageContainer>
    </ThemeProvider>
  );
}

export default App;
