import React from "react";
import styled from "styled-components";

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 25px;
  background-color: #3b064d;
  color: white;
  text-align: center;
  padding-top: 5px;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 14px;
`;

const Link = styled.a`
  color: #fe59d7;
  font-family: "Kanit", sans-serif;
  text-decoration: none;
`;

function Footer() {
  return (
    <FooterContainer>
      <Link href="https://github.com/tfcb93" target="_blank">
        tfcb93
      </Link>
      は心を込めて作った
    </FooterContainer>
  );
}

export default Footer;
