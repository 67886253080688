import React from "react";
import styled from "styled-components";
import { Sun, Moon } from "react-feather";
import { light, dark } from "../../utils/themes";

const Switch = styled.div`
  & > svg {
    stroke: ${(props) => props.theme.color.secondary};
  }

  &:hover {
    cursor: pointer;
  }
`;

function ThemeSwitch({ theme, changeTheme }) {
  const switchTheme = () => {
    if (theme.name === "light") {
      changeTheme(dark);
    } else {
      changeTheme(light);
    }
  };

  if (theme.name === "light") {
    return (
      <Switch onClick={switchTheme}>
        <Sun color="#fe59d7" />
      </Switch>
    );
  } else {
    return (
      <Switch onClick={switchTheme}>
        <Moon color="#fe59d7" />
      </Switch>
    );
  }
}

export default ThemeSwitch;
